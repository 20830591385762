import React from "react"
import styles from "../footer.module.css"

const Footer = () => {
  return (
    <footer id="footer">
      <h3>Contacto</h3>
      <div className={styles.cont1}>
        <address>
          Julio Correa N° 640 casi Avda. Santísima Trinidad Asunción - Paraguay
        </address>
        <a href="#" className={styles.googleLink}>
          Como llegar - Google maps
        </a>
      </div>
      <div className={styles.cont2}>
        <a href="">contacto@estudiotfz.com</a>
        <span>+595 21 123 456</span>
        <div className={styles.socialIcons}>
          {/* TODO:  Leer como trabajar con SVGs*/}
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 0H5C2.239 0 0 2.239 0 5V19C0 21.761 2.239 24 5 24H19C21.762 24 24 21.761 24 19V5C24 2.239 21.762 0 19 0ZM16 7H14.076C13.461 7 13 7.252 13 7.889V9H16L15.762 12H13V20H10V12H8V9H10V7.077C10 5.055 11.064 4 13.461 4H16V7Z"
              fill="#D19B20"
            />
          </svg>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 0H5C2.239 0 0 2.239 0 5V19C0 21.761 2.239 24 5 24H19C21.762 24 24 21.761 24 19V5C24 2.239 21.762 0 19 0ZM18.861 9.237C19.07 13.854 15.627 19.002 9.531 19.002C7.677 19.002 5.952 18.459 4.499 17.527C6.241 17.732 7.979 17.249 9.359 16.168C7.922 16.141 6.71 15.192 6.293 13.888C6.808 13.986 7.314 13.957 7.775 13.832C6.196 13.515 5.107 12.093 5.142 10.572C5.584 10.818 6.091 10.966 6.628 10.983C5.167 10.006 4.753 8.076 5.612 6.6C7.231 8.586 9.65 9.893 12.378 10.03C11.899 7.977 13.458 6 15.577 6C16.52 6 17.374 6.398 17.972 7.037C18.72 6.89 19.423 6.617 20.058 6.241C19.812 7.008 19.292 7.651 18.615 8.057C19.279 7.977 19.912 7.801 20.5 7.54C20.061 8.196 19.504 8.774 18.861 9.237Z"
              fill="#D19B20"
            />
          </svg>

          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 0H5C2.239 0 0 2.239 0 5V19C0 21.761 2.239 24 5 24H19C21.762 24 24 21.761 24 19V5C24 2.239 21.762 0 19 0ZM8 19H5V8H8V19ZM6.5 6.732C5.534 6.732 4.75 5.942 4.75 4.968C4.75 3.994 5.534 3.204 6.5 3.204C7.466 3.204 8.25 3.994 8.25 4.968C8.25 5.942 7.467 6.732 6.5 6.732ZM20 19H17V13.396C17 10.028 13 10.283 13 13.396V19H10V8H13V9.765C14.396 7.179 20 6.988 20 12.241V19Z"
              fill="#D19B20"
            />
          </svg>
        </div>
      </div>
      <div className={styles.disclaimer}>
        Diseño y desarrollo por Christian Bentele, Cecilia Buyatti y Lucas Boh ·
        Todos los derechos reservados © {new Date().getFullYear()}
      </div>
    </footer>
  )
}

export default Footer
