import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--blanco);
  color: var(--azul-100);
  max-width: 465px;
  margin-bottom: calc(2 * var(--lh-base));

  .imgContainer {
    width: 100%;
    height: 100%;
  }

  .imgContainer img {
    width: 100%;
    height: 100%;
    max-width: 465px;
    object-fit: cover;
  }

  .textWrapper {
    padding: 30px 24px 60px;
  }

  .textWrapper h3 {
    margin-bottom: calc(0.5 * var(--lh-base));
  }

  @media only screen and (min-width: 1000px) {
    margin-left: 120px;
  }

  @media only screen and (min-width: 1200px) {
    flex-direction: row;
    max-width: 1400px;
    margin-bottom: 90px;
    margin-left: 0;

    .imgContainer {
      width: 40%;
      height: 100%;
    }

    .textWrapper {
      width: 60%;
      padding: 52px 70px;
    }
  }
`

export default function areaDePractica({ area: { titulo, descripcion, img } }) {
  return (
    <Container>
      <div className="imgContainer">
        <Img fluid={img} />
      </div>
      <div className="textWrapper">
        <h3>{titulo}</h3>
        <p>{descripcion}</p>
      </div>
    </Container>
  )
}
