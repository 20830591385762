import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import Menu from "./DEmenu"
import styles from "../header.module.css"
import logoCompleto from "../../images/logoCompleto.svg"
import logo from "../../images/logo.svg"

const Header = ({ isIndex }) => {
  const [path, setPath] = useState("") //El path de la pagina actual
  useEffect(() => {
    setPath(window.location.pathname)
  }, [])

  let logoCorrespondiente
  if (isIndex) {
    logoCorrespondiente = logo
  } else {
    logoCorrespondiente = logoCompleto
  }
  const activeLink = { color: "#D19B20" }
  return (
    <header className={styles.header}>
      <div className={styles.subContainer1}>
        <div className={styles.logoContainer}>
          <Link to="/">{logoCorrespondiente}</Link>
        </div>
      </div>
      <div className={styles.menuDesktop}>
        <div className={styles.linksContainer}>
          <div className={styles.links}>
            <Link to="/">Inicio</Link>
            <Link to="/nosotros">Quienes Somos</Link>
            <Link to="/areas-de-practica">Áreas de Práctica</Link>
            <a href="#footer">Contacto</a>
          </div>
        </div>

        <div className={styles.idiomasContainer}>
          <div className={styles.idiomas}>
            <Link to={path.replace("/de", "")} activeStyle={activeLink}>
              ES
            </Link>
            <Link to={path.replace("/de", "/en")} activeStyle={activeLink}>
              EN
            </Link>
            <Link to={path.replace("/de", "/fr")} activeStyle={activeLink}>
              FR
            </Link>
            <Link to={path} activeStyle={activeLink}>
              DE
            </Link>
          </div>
        </div>
      </div>
      <Menu path={path} />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
